import React from 'react'
import './index.scss'
import 'animate.css';
import { VStack, Center, Container, Text, Image } from '@chakra-ui/react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import { Parallax } from 'react-parallax';
import BlogItems from './blogItems';

const Blogs = () => {
    return (
        <div className='blogs-container'>
            <Parallax blur={20} bgImage={require('../../assets/images/cyber.png')} bgImageAlt="the cat" strength={800} style={{
                width: "100%",
                height: "100%",
            }}>
                <div className={'content'}>
                    <VStack >
                        <Container maxW='container.md'>
                            <Center pt={16} m={2}>
                                <Text fontSize={54} color='white' textShadow={'4px 4px 5px black'}>Blogs</Text>
                            </Center>
                            <Center pb={16} m={2}>
                                <Text fontSize={24} color='white' textAlign={'center'} textShadow={'4px 4px 5px black'}>All the videos/tutorials are for educational purposes only. I am not responsible for the misuse of it.</Text>
                            </Center>
                        </Container>

                        <div className={'row'}>
                            <div className={'row-item'}>
                                <div style={{ background: 'rgba(0,0,0,0.5)', borderBottomLeftRadius: 12, borderBottomRightRadius: 12, paddingBottom: 12 }}>
                                    <iframe
                                        src="https://www.youtube.com/embed/1nrFGxP4b9w">
                                    </iframe>
                                    <div style={{ margin: 24 }}>
                                        <h1>Local File Inclusion + Remote Code Execution + Server Rooting using Pentestkit Mobile</h1>
                                        <h3>@thejolotoproject, September 25, 2024</h3>
                                        <BlogItems>
                                            <div className={'p-content'}>
                                                <p>The main objective of this video tutorial is to perform a Local File Inclusion, Upload a Shell to it, and then exploit the server to gain access root!.</p><br />

                                                <p>1. Check if the website is vulnerable to Local File Inclusion attack by finding specific path like /etc/passwd, proc/self/environ and /var/log/apache2/error.log using ../../ .</p>
                                                <p>2. Check if we can run and get the version of PHP</p>
                                                <p>3. We intended to log this error &lt;?php system($_REQUEST(‘x’)) ?&gt; in /var/log/apache2/error.log to be our little shell temporary</p>
                                                <p>4. Using the path /var/log/apache2/error.log?x=ANY_COMMAND we can have a data we need or make a CRUD: create, read, update, delete, upload a file or dir to a server.</p>
                                                <p>5. Storing shell in error.log is good, but what we need  is an interface and more readable, that’s why we uploaded a shell generated by Pentestkit Mobile.</p>
                                                <p>6. Using Persistent Shell we can upload and extract our exploit into the server</p>
                                                <p>7.  We run the exploit in our Persistent Shell but we can’t get the ROOT session, this is because our shell script is running in PHP, and we need more higher shell to be able to run as ROOT. SSH it is.</p>
                                                <p>8. We need to create a new user for us to be able to run in ssh, you can edit too, it’s your technique on how you can be able to gain access to ssh. You have to explore.</p>
                                                <p>9. Once we are inside of ssh, we locate the directory of our uploaded exploit file and run it, and yeah that it is. We are now the ROOT</p>
                                            </div>
                                        </BlogItems>
                                    </div>
                                </div>
                            </div>

                            <div className={'row-item'}>
                                <div style={{ background: 'rgba(0,0,0,0.5)', borderBottomLeftRadius: 12, borderBottomRightRadius: 12, paddingBottom: 12 }}>
                                    <iframe
                                        src="https://www.youtube.com/embed/fQy0-CQ6xAo">
                                    </iframe>
                                    <div style={{ margin: 24 }}>
                                        <h1>How to Perform Offensive Security & Compromised a web server using PentestKit Mobile</h1>
                                        <h3>@thejolotoproject, Jul 18, 2023</h3>
                                        <BlogItems>
                                            <div className={'p-content'}>
                                                <p>The main objective of this video tutorial is to perform an offensive attack to a web server and install persistent shell so that we can easily connect to the server again and again.</p><br />

                                                <p>1. Google Dorker – We use google dork to find a vulnerable website depends in our desired query ex: inurl:producsts.php?=1 then all the links return will be</p>
                                                <p>- https://sample1.com?products.php=1 </p>
                                                <p>- https://sample2.com/home?products.php=1 etc.. etc.. etc..</p>

                                                <p>2. Admin Finder – We use admin finder tool to find the Administrator or Control Panel of the website where we going to upload the shell, in my first login I used the default user and password of DVWA, and then to my second login we use the credential we got in SQL Injection.</p>
                                                <p>3. Advanced Hackbar – Using this tool, We can inject malicious query to the target by performing SQL Injection such as Post Based, XSS, WAF BYPASS, Union Based, Error Based, Boolean Based, Time-based blind also buffer overflow.</p>
                                                <p>4. Browser – We use browser to go to a third party website decrypter, I recommend the https://md5decrypt.net it is good and have a large database of password.</p>
                                                <p>5. Persisted Shells – Using this feature, allows us to manage and connect to our previously compromised server with one click. Shells created by our app can’t access by any browsers or any tools. It should only work and connect to our Pentestkit Mobile and has a security itself.</p>
                                            </div>
                                        </BlogItems>
                                    </div>
                                </div>
                            </div>


                            <div className={'row-item'}>
                                <div style={{ background: 'rgba(0,0,0,0.5)', borderBottomLeftRadius: 12, borderBottomRightRadius: 12, paddingBottom: 12 }}>
                                    <Image src="https://github.com/thejolotoproject/blind-rce/blob/master/docs/demo.gif?raw=true" objectFit={"contain"} />
                                    <div style={{ margin: 24 }}>
                                        <h1>Time Based / Blind Remote Code Execution</h1>
                                        <h3>@thejolotoproject, Oct 22, 2024</h3>
                                        <BlogItems>
                                            <div className={'p-content'}>
                                                <p>A Python-based program for Time-based/Blind Remote Code Execution (RCE). The main objective of this program is to extract the data that is not visible and/or not returned in the response of vulnerable target. It uses time-based techniques by sending a payload to the target with a command specified and then waiting for its response.</p><br />
                                                <a href='https://github.com/thejolotoproject/blind-rce' target='_blank'>Github: https://github.com/thejolotoproject/blind-rce</a>
                                            </div>
                                        </BlogItems>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </VStack>
                </div>
            </Parallax>
        </div >
    )
}

export default Blogs