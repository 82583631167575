import './index.scss'
import { Wrap, WrapItem, Center } from '@chakra-ui/react'
import { Link } from "react-scroll";

const About = () => {
    return (
        <div className='about-container'>
            <Center py={32}>
                <Wrap justify={'space-evenly'} w={'100%'}>
                    <WrapItem>
                        <Center w='350px' >
                            <h1>About Me</h1>
                        </Center>
                    </WrapItem>
                    <WrapItem>
                        <Center maxW='910px' >
                            <div className='description' >
                                <p> @thejolotoproject is a sole developer that builds web and mobile applications using the modern programming languages and techniques today.
                                </p><br />
                                <p>
                                    I started to learn and study Cybersecurity in 2012, doing some Penetration Testing to a System / App and participating in other related events like CTF, SQLi / XSS Challenge, Cyberwar, etc. Doing Offensive Security was my favorite part. I used Backtrack 5 before Kali Linux 1.0 came out at that time (reminiscing about old times). I installed different Security Labs on my machine, used different scripts, made my own scripts, found and searched for 0-day exploits in exploit-db and tested it all as much as I could (on my local machine, of course). That&apos;s how I have been obsessive to cybersecurity since then.
                                </p><br />
                                <p>In 2017, I became Software Engr. Focusing on developing a System / Application, Planning, Structure Designs, Converting UI Designs into actual Web Pages, Creating API (Application Programming Interface), Processing the data, then saving it to a Database. Those are Frontend and Backend Tasks. As time goes by, I also started to set up and handle Servers and Domains, most likely a DevOps task up to this current year 2024.
                                </p><br />
                                <p >  I also love music, playing musical instruments like guitar, bass, drums and keyboard. Listening to the old songs from the 90&apos;s is my favorite. Of course!  Don&apos;t forget the OPM!
                                </p><br />
                                <Link
                                    style={{ display: 'block' }}
                                    activeClass="active"
                                    to="contact"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                > <button className={'btn'}>Hire Me?</button></Link>

                            </div>
                        </Center>
                    </WrapItem>
                </Wrap>
            </Center>
        </div >
    )
}

export default About