"use client"
import React from "react";
import './index.scss'
import { Link } from "react-scroll";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button,
    IconButton
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'
const Nav = () => {
    return (
        <div className="nav-container">
            <div className="nav-mobile">
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<HamburgerIcon color={'white'} />}
                        variant='outline'
                    />
                    <MenuList>
                        <MenuItem style={{ display: 'block' }}> <Link
                            style={{ display: 'block' }}
                            activeClass="active"
                            to="home"
                            spy={true}
                            smooth={true}
                            duration={500}
                        > Home</Link></MenuItem>
                        <MenuItem style={{ display: 'block' }}> <Link
                            style={{ display: 'block' }}
                            activeClass="active"
                            to="about"
                            spy={true}
                            smooth={true}
                            duration={500}
                        > About</Link></MenuItem>
                        <MenuItem style={{ display: 'block' }}> <Link
                            style={{ display: 'block' }}
                            activeClass="active"
                            to="product"
                            spy={true}
                            smooth={true}
                            duration={500}
                        > Products</Link></MenuItem>
                        <MenuItem style={{ display: 'block' }}> <Link
                            style={{ display: 'block' }}
                            activeClass="active"
                            to="blog"
                            spy={true}
                            smooth={true}
                            duration={500}
                        > Blogs</Link></MenuItem>
                        <MenuItem style={{ display: 'block' }}> <Link
                            style={{ display: 'block' }}
                            activeClass="active"
                            to="contact"
                            spy={true}
                            smooth={true}
                            duration={500}
                        > Contact</Link></MenuItem>
                    </MenuList>
                </Menu>
            </div>
            <div className={'nav-web'}>
                <li>

                </li>
                <ul>
                    <li>
                        <Link
                            activeClass="active"
                            to="home"
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            to="about"
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            About
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            to="product"
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            Products
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            to="blog"
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            Blogs
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            to="contact"
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            Contact
                        </Link>
                    </li>
                </ul>
            </div >
        </div>

    );
};
export default Nav;
