
import { ChakraProvider } from "@chakra-ui/react";
import Nav from './shared/nav'
import './index.scss'
import 'animate.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import About from "./components/about";
import Products from "./components/products";
import Timeline from "./components/home";
import Blogs from "./components/blogs";
import Contact from "./components/contact";

export default function App() {
  return (
    <ChakraProvider>
      <Nav />
      <div className={'page1'} id="home">
        <Timeline />
      </div >
      <div className={'page2'} id="about">
        <About />
      </div>
      <div className={'page3'} id="product">
        <Products />
      </div>
      <div className={'page4'} id="blog" >
        <Blogs />
      </div>
      <div className={'page5'} id="contact" >
        <Contact />
      </div>
    </ChakraProvider >
  );
}

