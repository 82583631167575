import React, { useState } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Text
} from '@chakra-ui/react'
import { MinusIcon, AddIcon } from '@chakra-ui/icons'

interface IProps {
    children: any;
}
const BlogItems = (props: IProps) => {
    return (
        <Box mt={4}>
            <Accordion allowMultiple>
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'>
                                <Text color='white'>View Details</Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {props.children}
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>
    )
}


export default BlogItems