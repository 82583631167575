import 'animate.css';
import { Center, Container, Text, Box } from '@chakra-ui/react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
const Contact = () => {
    return (
        <Container maxW='container.lg'>
            <Center p={'4vh'}>
                <Text color={"white"} fontSize={24} fontStyle={'italic'} textAlign='justify' style={{fontFamily:'Montserrat'}} >Have you found bugs/errors in the application? If you want to report them, just bump me at my email.
                    <br /> <br /><b>OR</b> <br /><br />Is there something on your mind? Software? System? Let&apos;s develop it and make it real. Let&apos;s talk about business. <b>Hire me!. </b>
                    <br /><br />Contact: <b style={{ color: 'yellow' }}>thejolotoproject@gmail.com</b></Text>
            </Center>
        </Container>
    )
}

export default Contact